import React from 'react';

import AppImage from '../AppImage';
import AppContainer from '../AppContainer/AppContainer';
import AppLink from '../AppLink';
import AppButton from '../AppButton/AppButton';

import styles from './AppError.module.scss';

function AppError(props) {
  return (
    <div className={styles.AppError}>
      <AppContainer>
        <div className={styles.AppError__Wrapper}>
          {props.first && (
            <div className={styles.AppError__Wrapper__V1}>
              <span>sorry it’s gone...</span>
              <div className={styles.AppError__Wrapper__V1__Image}>
                <AppImage src="/images/Error.png" />
              </div>
              <div className={styles.AppError__Wrapper__Button}>
                <AppLink href="/" ariaLabel={'Back Home'} title={'Back Home'}>
                  <AppButton round>Back Home</AppButton>
                </AppLink>
              </div>
            </div>
          )}
          {props.second && (
            <div className={styles.AppError__Wrapper__V2}>
              <div className={styles.AppError__Wrapper__V2__Image}>
                <AppImage src="/images/Error2.png" />
              </div>
              <div className={styles.AppError__Wrapper__Button}>
                <AppLink href="/" ariaLabel={'Back Home'} title={'Back Home'}>
                  <AppButton round>Back Home</AppButton>
                </AppLink>
              </div>
            </div>
          )}
        </div>
      </AppContainer>
    </div>
  );
}

export default AppError;
