import { useEffect, useState } from 'react';

import AppError from '../components/AppError/AppError';
import Layout from '../layout/AppLayout';
import AppHead from '../components/AppHead';

export default function Error() {
  const [randomItem, setRandomItem] = useState(null);

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * 2); // Generate a random index (either 0 or 1)
    const items = [1, 2];
    // eslint-disable-next-line computed-property-spacing
    setRandomItem(items[randomIndex]);
  }, []);

  return (
    <Layout isError>
      <AppHead metaTitle={'Error'} metaDescription={'Error Page'} noIndex />

      {/* pass first or second to change design*/}
      {randomItem === 1 ? <AppError first /> : <AppError second />}
    </Layout>
  );
}
